import * as notificationTypes from '../../../constants/notification-types'

let id = 0

const createNotification = (options = {}) => {
  const {
    notificationType = notificationTypes.INFO,
    header = '',
    content = 'Что-то произошло',
    timeout = 5000,
  } = options

  id += 1

  return {
    id,
    notificationType,
    header,
    content,
    timeout,
  }
}

export default createNotification
